$(document).ready(initPage);

function initPage() {
  $('.topmenu .parent-link').click(function () {
    $(this).parent().toggleClass('active');
    $(this).parent().siblings().removeClass('active');
    return false;
  });
  $('.topmenu .parent').hover(
    function () {
      $(this).addClass('active');
      $(this).siblings().removeClass('active');
    },
    function () {
      $(this).removeClass('active');
    }
  );
  $('.dropdown-collapse').click(function () {
    $(this).parents('.parent').removeClass('active');
  });

  $('.search-toggler').click(function () {
    $(this).parent().toggleClass('active');
  });

  $(document).mouseup(function (e) {
    var container = $('.search');

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.removeClass('active');
    }
  });

  const swiper1 = new Swiper('.swiper-view-1', {
    loop: true,
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-view-1 .swiper-button-next',
      prevEl: '.swiper-view-1 .swiper-button-prev',
    },
  });

  const swiper2 = new Swiper('.swiper-view-2', {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 20,
    pagination: false,
    navigation: {
      nextEl: '.swiper-view-2-container .swiper-button-next',
      prevEl: '.swiper-view-2-container .swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  });

  const swiper3 = new Swiper('.swiper-view-3', {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 50,
    pagination: false,
    navigation: {
      nextEl: '.swiper-view-3-container .swiper-button-next',
      prevEl: '.swiper-view-3-container .swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  });

  const swiper4 = new Swiper('.swiper-view-4', {
    loop: false,
    slidesPerView: 'auto',
    spaceBetween: 20,
    pagination: false,
    centeredSlides: false,
    navigation: {
      nextEl: '.swiper-view-4-container .swiper-button-next',
      prevEl: '.swiper-view-4-container .swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
      },
      576: {
        slidesPerView: 'auto',
        centeredSlides: false,
        loop: false,
      },
    },
  });

  $('.gallery-load .btn').click(function () {
    $('.gallery-row.hidden:first').removeClass('hidden');
    if ($('.gallery-row.hidden').length == 0) {
      console.log($('.gallery-row.hidden').length);
      $(this).addClass('hidden');
    }
  });

  $('.album > a').on('click', function (event) {
    event.preventDefault();

    let gallery = $(this).attr('href');

    $(gallery)
      .magnificPopup({
        delegate: 'a',
        type: 'image',
        fixedContentPos: false,
        gallery: {
          enabled: true,
        },
      })
      .magnificPopup('open');
  });

  $('.contact-toggler').click(function () {
    $('.contact-sticky').toggleClass('active');
  });

  $('.menu-toggler').click(function () {
    $('.topmenu').toggleClass('active');
    $('body').toggleClass('topmenu-active');
  });

  $('.privacy-accept .btn').click(function () {
    $(this).parents('.footer-privacy').addClass('hidden');
    return false;
  });

  /* Article */

  const swiper5thumbs = new Swiper('.swiper-view-5-thumbs', {
    loop: true,
    slidesPerView: 5,
    loopedSlides: 5,
    spaceBetween: 20,
    slideToClickedSlide: true,
    breakpoints: {
      320: {
        slidesPerView: 3,
      },
      576: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 5,
      },
    },
  });
  const swiper5 = new Swiper('.swiper-view-5', {
    loop: true,
    loopedSlides: 5,
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: false,
    navigation: false,
    thumbs: {
      swiper: swiper5thumbs,
    },
    navigation: {
      nextEl: '.swiper-view-5-thumbs-container .swiper-button-next',
      prevEl: '.swiper-view-5-thumbs-container .swiper-button-prev',
    },
  });
  swiper5.controller.control = swiper5thumbs;

  const swiper6thumbs = new Swiper('.swiper-view-6-thumbs', {
    loop: true,
    slidesPerView: 5,
    loopedSlides: 5,
    spaceBetween: 20,
    slideToClickedSlide: true,
    breakpoints: {
      320: {
        slidesPerView: 3,
      },
      576: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 5,
      },
    },
  });
  const swiper6 = new Swiper('.swiper-view-6', {
    loop: true,
    loopedSlides: 5,
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: false,
    navigation: false,
    thumbs: {
      swiper: swiper5thumbs,
    },
    navigation: {
      nextEl: '.swiper-view-6-thumbs-container .swiper-button-next',
      prevEl: '.swiper-view-6-thumbs-container .swiper-button-prev',
    },
  });
  swiper6.controller.control = swiper5thumbs;

  const swiper7 = new Swiper('.swiper-view-7', {
    loop: false,
    slidesPerView: 2,
    spaceBetween: 20,
    pagination: false,
    centeredSlides: false,
    navigation: {
      nextEl: '.swiper-view-7 .swiper-button-next',
      prevEl: '.swiper-view-7 .swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
      },
      576: {
        slidesPerView: 2,
        centeredSlides: false,
        loop: false,
      },
      768: {
        slidesPerView: 3,
        centeredSlides: false,
        loop: false,
      },
      992: {
        slidesPerView: 2,
        centeredSlides: false,
        loop: false,
      },
    },
  });
}
